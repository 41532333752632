const { gtag } = require('@hutson/utils')
const siteMetadata = require('./src/meta')

exports.onClientEntry = () => {
  if (process.env.NODE_ENV === 'production') {
    gtag('config', process.env.GATSBY_GOOGLE_ANALYTICS_ID, {
      app_name: siteMetadata.analyticsAppName,
      currency: 'USD',
      linker: {
        domains: ['hutsoninc.com', 'build.hutsoninc.com', 'fieldguide.hutsoninc.com'],
      },
      send_page_view: false,
    })
  }
}
