const meta = {
  analyticsAppName: 'field-guide',
  description: '',
  image: '/hutson-inc-og.jpg',
  siteUrl: 'https://fieldguide.hutsoninc.com',
  title: 'Field Guide | Huston Inc',
  twitterUsername: '@Hutson_Inc',
}

module.exports = meta
